@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.search {
  input {
    border-radius: 27px 0 0 27px;
    padding: rfs-value(13px 24px);
    font-size: rfs-value(24px);
    border: none;
    color: $nikon-black;

    &::placeholder {
      color: $nikon-dark-grey;
      font-family: $font-family-serif;
      font-style: italic;
      letter-spacing: 1px;
    }
    &::-moz-placeholder {
      color: $nikon-dark-grey;
      font-family: $font-family-serif;
      font-style: italic;
      letter-spacing: 1px;
    }
    &:-ms-input-placeholder {
      color: $nikon-dark-grey;
      font-family: $font-family-serif;
      font-style: italic;
      letter-spacing: 1px;
    }
    &::-webkit-input-placeholder {
      color: $nikon-dark-grey;
      font-family: $font-family-serif;
      font-style: italic;
      letter-spacing: 1px;
    }
  }
  button {
    border-radius: 0 27px 27px 0 !important;
    border: none;
    padding-left: rfs-value(16px);
  }

  &.menu {
    input {
      font-size: rfs-value(16px);
    }
    button {
    }

    @include media-breakpoint-up(md) {
      input {
        padding: rfs-value(5px 0);
        font-size: rfs-value(20px);
        border: none;
        border-bottom: 1px solid $nikon-white;
        border-radius: 0;
        background: $nikon-black;
        color: $nikon-white;
        margin: rfs-value(10px 0 8px 50px);

        &::placeholder {
          color: $nikon-white;
        }
        &::-moz-placeholder {
          color: $nikon-white;
        }
        &:-ms-input-placeholder {
          color: $nikon-white;
        }
        &::-webkit-input-placeholder {
          color: $nikon-white;
        }
      }
      button {
        border-radius: 50% !important;
        padding: 15px;
        width: 54px;
        height: 54px;
        background: none;
        position: absolute;
        left: 0;

        svg {
          stroke: white;
        }
      }
    }
  }
  &.hero {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
      margin: rfs-value(40px auto);
    }
  }
}
