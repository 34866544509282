@import '../../../../../../styles/variables';
@import 'bootstrap/scss/mixins';

.full-specs {
  margin: rfs-value(40px) auto rfs-value(35px);

  @include media-breakpoint-up(md) {
    max-width: 750px;
  }

  @include media-breakpoint-up(lg) {
    max-width: 970px;
  }
}

.accordion-container {
  .accordion-item {
    border: 0;
    margin-top: rfs-value(36px);

    &-header {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: rfs-value(8px) 0;
      border-bottom: 1px solid $nikon-light-grey;
      width: 100%;

      &:focus {
        outline: 0;
        box-shadow: none;

        &::before {
          content: '';
          left: -15px;
          right: -15px;
          height: 100%;
          position: absolute;
          box-shadow: 0 0 10px $nikon-box-shadow-color;
          border-radius: 2px;
        }
      }

      &-title {
        margin: 0;
        font-size: rfs-value(24px);
        font-weight: $font-weight-light;
        color: $nikon-dark-grey;
      }
    }

    &-body {
      padding: 0 rfs-value(15px);

      &-list {
        padding: 0;
      }

      &-item {
        padding-top: rfs-value(15px);
        padding-bottom: rfs-value(12px);
        font-size: rfs-value(12px);
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        &-title {
          font-weight: $font-weight-bold;
        }

        &-values-container {
          display: flex;
          flex-direction: column;
        }
      }
    }

    @include media-breakpoint-down(md) {
      svg {
        display: none;
      }

      & > div {
        display: block;
      }

      &-header {
        cursor: default;
        pointer-events: none;
        padding-top: 0;

        &-title {
          font-size: rfs-value(18px);
          font-weight: $font-weight-bold;
        }
      }

      &-body {
        &-list {
          display: flex;
          flex-direction: column;
          padding: rfs-value(14px) 0;
          gap: rfs-value(14px);
        }

        &-item {
          display: flex;
          flex-direction: column;
          padding: 0;
          gap: rfs-value(6px);
        }
      }
    }
  }
}
