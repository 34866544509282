@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.lne-sub-nav {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  color: #fff;
  height: auto;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.9);

  &:before {
    background: rgba(0, 0, 0, 0.3);
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }
}

.lne-hero-nav {
  position: relative;
  z-index: 1;
  text-align: center;
  padding-top: rfs-value(1px);
  padding-bottom: rfs-value(1px);
  max-width: 970px;

  .logo {
    margin: rfs-value(60px auto);
    width: 200px;

    @include media-breakpoint-up(md) {
      margin: rfs-value(80px auto);
      width: 300px;
    }

    &.has-search {
      margin: rfs-value(30px auto);

      @include media-breakpoint-up(md) {
        margin: rfs-value(40px auto);
      }
    }
  }
  .headline {
    background: rgba(0, 0, 0, 0.5);
    font-size: rfs-value(36px);
    font-weight: 500;
    margin: rfs-value(30px auto);
    padding: rfs-value(15px);
    border-radius: 15px;

    @include media-breakpoint-up(md) {
      font-size: rfs-value(72px);
      line-height: 1;
      margin: rfs-value(40px auto);
      padding: rfs-value(30px 15px);
    }
  }
}
