@import '../../../../styles/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/vendor/rfs';

.brow {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  background: black;
  border-bottom: 1px solid rgba($nikon-yellow, 0.25);
  margin-bottom: rfs-value(120px);

  @include media-breakpoint-up(md) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 99;
  }

  :global(.buy-now) &,
  &:has(+ :global(.tab-content) > :global(.overview-tab)),
  &:has(+ :global(.tab-content) > :global(.tech-specs-tab)) {
    margin-bottom: 0;
  }

  .productInfo {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: auto;
    }
  }

  .browNav {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: auto;
    }
  }

  :global(.dropdown-toggle),
  :global(.dropdown-item),
  :global(.nav-link),
  :global(.navbar-toggler),
  .buyButton {
    font-size: rfs-value(12px);
  }

  :global(.dropdown-toggle),
  :global(.dropdown-item),
  :global(.nav-link),
  :global(.navbar-toggler) {
    padding: 13px 15px;
    font-weight: $font-weight-bold;
    line-height: 1.7;
    color: white;
    letter-spacing: rfs-value(1.2px);
    text-transform: uppercase;
    text-decoration-color: transparent;
    transition: text-decoration 0.2s ease-in;

    @include media-breakpoint-up(md) {
      padding: rfs-value(26px 15px);
    }

    &:active,
    &:focus,
    &:hover,
    &:global(.active) {
      color: white;
      text-decoration: underline;
      text-decoration-color: $nikon-yellow;
      text-decoration-thickness: rfs-value(2px);
      text-underline-offset: rfs-value(7px);
      box-shadow: none;
    }
  }

  :global(.dropdown-toggle) {
    background: none;
    border: none;

    &:active,
    &:focus,
    &:hover,
    &:global(.active) {
      background: none;
      border: none;
    }
  }

  :global(.dropdown-menu) {
    border-radius: 0;
    background: black;
    border: none;
    @include media-breakpoint-up(md) {
      right: 0;
      left: unset;
    }
  }

  :global(.dropdown-item) {
    font-size: rfs-value(11px);
    padding: rfs-value(12px 30px 13px) !important;

    &,
    &:active,
    &:focus,
    &:hover,
    &:global(.active) {
      background: black;
      border: none;
    }
  }

  :global(.nav-item) {
    &:first-child {
      margin-top: 15px;
    }

    @include media-breakpoint-up(md) {
      display: flex;
      align-items: center;
      width: auto;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .navBarMenu {
    @include media-breakpoint-up(md) {
      align-items: center;
    }
  }

  .browNavItem,
  .buyButtonBLock {
    padding: rfs-value(15px) !important;
  }

  .buyButtonBLock {
    @include media-breakpoint-down(md) {
      display: none !important;
    }
  }

  .buyButton {
    padding: 0.5625rem 2.0625rem 0.5rem;
  }

  // START OF MORE TAB STYLES
  .moreTab {
    padding-right: 15px !important;

    :global(.dropdown-toggle::after) {
      border-top: 0;
    }
  }

  .moreTab:global(.dropdown) {
    :global(.dropdown-menu) {
      display: block;
      opacity: 0;
      max-height: 0;
      transition: max-height, opacity 0.35s ease-out;
      overflow: hidden;
      text-align: right;
    }

    &:hover,
    &:active {
      :global(.dropdown-menu) {
        display: block;
        margin-top: 0;
        opacity: 1;
        max-height: 100vh;
        transition: max-height 0.35s ease-in;
      }
    }
  }

  .navigateDownIcon {
    position: absolute;
    top: 40%;
    right: 14px;

    &::before {
      content: url(/icons/downArrowWhite.svg);
    }
  }
  // END OF MORE TAB STYLES

  // START OF MOBILE MENU STYLES
  .mobileMenuButton {
    background: 0 0;
    border: none;
    width: 100%;
    text-align: left;
    position: relative;
    border-radius: 0;
  }

  .browMobileIcon {
    position: absolute;
    margin-top: 5px;
    top: 50%;
    right: 15px;

    &.open,
    &.close {
      transform: translateY(-50%);
      transition: opacity 350ms ease-out;
      will-change: opacity;
    }

    &.open {
      margin-right: -2.4px;
      margin-top: 7px;

      &::before {
        content: url(/icons/downArrowWhite.svg);
      }
    }

    &.close {
      &::before {
        content: url(/icons/closeIconWhite.svg);
      }
    }
  }

  :global(.navbar-toggler.collapsed) {
    .browMobileIcon {
      &.open {
        opacity: 1;
      }

      &.close {
        opacity: 0;
      }
    }
  }

  :global(.navbar-toggler) {
    .browMobileIcon {
      &.open {
        opacity: 0;
      }

      &.close {
        opacity: 1;
      }
    }
  }

  .mobileShopTab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    height: 73px;
    padding: 15px;
    bottom: 0;
    background-color: #000;
    z-index: 10;

    .productInfo {
      width: auto;
    }

    @include media-breakpoint-up(md) {
      display: none;
    }
  }
}

.ratingSection {
  cursor: pointer;
}
