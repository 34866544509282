@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.lne-slider {
  margin: rfs-value(40px 0);

  @include media-breakpoint-up(lg) {
    margin: rfs-value(60px 0);
    padding: rfs-value(0 15px);
  }

  h2 {
    margin-bottom: rfs-value(40px);
    text-align: center;
    font-size: rfs-value(30px);
    line-height: 1.2;

    @include media-breakpoint-up(lg) {
      margin-bottom: rfs-value(60px);
    }
  }
  :global {
    .slick-slider {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: center;
      padding: 0;

      @include media-breakpoint-up(md) {
        padding: rfs-value(0 30px);
      }
      @include media-breakpoint-up(lg) {
        padding: rfs-value(0 15px);
      }
    }
    .slick-list {
      order: 1;
      width: 100%;
      margin-bottom: rfs-value(10px);
    }
    .slick-arrow {
      position: relative;
      border-radius: 50%;
      background: #d3d3d3;
      width: rfs-value(45px);
      height: rfs-value(45px);
      top: unset;
      right: unset;
      bottom: unset;
      left: unset;
      transform: unset;
      display: flex !important;
      justify-content: center;
      align-items: center;

      &:hover,
      &:active,
      &:focus {
        background: #a09f9f;

        &:before {
          filter: invert(1);
        }
      }
      &:before {
        position: relative;
        display: block;
        width: rfs-value(22px);
        height: rfs-value(22px);
      }
    }
    .slick-prev {
      order: 2;

      &:before {
        left: -1px;
        content: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>");
      }
    }
    .slick-next {
      order: 3;

      &:before {
        right: -1px;
        content: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>");
      }
    }
  }
}
