@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.lne-promotion-footer .item {
  text-align: center;

  img {
    margin-bottom: rfs-value(30px);
  }
  h3 {
    font-size: rfs-value(24px);
    line-height: 1.2;
    margin-bottom: rfs-value(10px);
  }
  p {
    font-size: rfs-value(12px);
    line-height: 1.5;
    margin-bottom: rfs-value(10px);
  }
  .link {
    font-size: rfs-value(12px);
    line-height: 1.5;
    color: $nikon-dark-grey;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 700;

    &:hover {
      color: $nikon-dark-grey;
      text-decoration: underline;
    }

    .icon {
      display: inline-block;
      margin-left: rfs-value(5px);
      font-size: 40px;
      position: relative;

      &::before {
        content: '';
        width: 11px;
        height: 11px;
        background: url(/icons/rightArrowYellow.svg) center no-repeat;
        background-size: contain;
        position: absolute;
        bottom: -1px;
        left: 0;
      }
    }
  }
}
