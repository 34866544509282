@import '../../../../../styles/variables';
@import 'bootstrap/scss/mixins';

.techSpecsTabPane {
  @include media-breakpoint-up(md) {
    padding-bottom: 0 !important;
  }
}

.pdp-footnotes {
  margin-bottom: rfs-value(40px);

  @include media-breakpoint-up(md) {
    margin-bottom: rfs-value(20px);
  }

  @include media-breakpoint-up(xl) {
    max-width: 1170px;
  }

  p {
    margin: 0 0 rfs-value(10px);
  }
}

.pdp-footnotes-text {
  font-size: 65%;
  width: 100%;
  float: left;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;

  @include media-breakpoint-up(md) {
    padding-left: 0;
    padding-right: 0;
  }
}
