.availability-message {
  color: #a94442;
  font-weight: bold;
  font-style: italic;
  font-size: rfs-value(12px);
  padding-top: 1rem;

  & button {
    position: absolute;
    padding-left: 5px;

    &:focus {
      outline: none;
      box-shadow: none;
    }

    & > div {
      &::before {
        content: "❓";
        color: #a94442;
        vertical-align: middle;
        text-rendering: optimizeLegibility;
        font-feature-settings: "liga";
        -webkit-font-smoothing: antialiased;
        font-family: SSStandard;
        font-style: normal;
        font-weight: 400;
        text-decoration: none;
        white-space: nowrap;
      }
    }
  }
}
