@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.service_advisory_article {
  overflow-wrap: anywhere;

  table {
    width: 100%;
    text-align: center;

    &,
    td {
      border: 1px solid $nikon-black;
    }
    p:last-child {
      margin-bottom: 0;
    }
    img {
      margin: 0 auto;
    }
  }

  .service_article_title {
    font-size: rfs-value(40px);
  }

  .classification_label {
    width: fit-content;
    padding: rfs-value(12px) rfs-value(20px);
    text-align: center;
    background-color: $nikon-light-grey;
    font-size: rfs-value(14px);
    text-transform: uppercase;
  }

  .date_block {
    text-transform: uppercase;
    letter-spacing: 4px;
    font-size: rfs-value(12px);
    font-weight: $font-weight-bold;
    .updated_badge {
      background-color: $nikon-yellow;
      color: $nikon-dark-grey;
      font-size: rfs-value(12px);
      border: 1px solid $nikon-yellow-hover;
      padding: 4px;
      margin-right: rfs-value(10px);
      letter-spacing: initial;
    }

    .original_date {
      .original_date_label {
        text-transform: capitalize;
        font-style: italic;
        margin-right: rfs-value(10px);
        font-weight: $font-weight-normal;
      }
    }
  }
}
