@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.page_header {
  width: 100%;

  .page_header_title {
    display: flex;
    align-items: center;
    align-content: center;
    height: 20vw;
    min-height: rfs-value(150px);
    max-height: rfs-value(200px);
    background-color: $nikon-black;

    h1 {
      margin: 0 auto;
      color: $nikon-white;
      font-weight: $font-weight-light;
      font-size: rfs-value(72px);
      @include media-breakpoint-down(sm) {
        font-size: rfs-value(36px);
      }
    }

    @include media-breakpoint-down(md) {
      padding: rfs-value(10px) 0;
      & > * {
        flex-wrap: wrap;
        gap: rfs-value(10px);
        flex-direction: column-reverse;
      }
      .results_text,
      .pressroom_filter,
      .pressroom_search {
        display: flex;
        //width: 100%;
        justify-content: center;
      }
      .pressroom_filter {
        margin: 0;
      }
    }
  }
}

.filter_search_bar {
  width: 100%;
  background: $nikon-black;
  padding: rfs-value(30px) 0;
  display: flex;
  color: $nikon-white;
  justify-content: right;

  .filter_search_bar_wrapper {
    display: flex;
    @include media-breakpoint-down(md) {
      flex-direction: column-reverse;
    }
  }

  & > * {
    display: flex;
  }

  .results_text {
    display: inline-flex;
    align-items: center;
    font-weight: $font-weight-light;
    font-size: rfs-value(18px);
    padding: 3px 0;
  }

  .pressroom_filter {
    display: inline-flex;
    justify-content: right;
    padding: 3px 0;
    button {
      max-width: rfs-value(150px);
      @include media-breakpoint-down(sm) {
        max-width: 100%;
      }
    }
  }

  .pressroom_search {
    display: inline-flex;
    align-items: center;
    justify-content: right;
    padding: 3px;
    @include media-breakpoint-up(md) {
      padding-left: rfs-value(50px);
    }
  }

  @include media-breakpoint-down(md) {
    padding: rfs-value(10px) 0;
    & > * {
      flex-wrap: wrap;
      gap: rfs-value(10px);
      flex-direction: column-reverse;
    }
    .results_text,
    .pressroom_filter,
    .pressroom_search {
      display: flex;
      width: 100%;
    }
    .pressroom_filter {
      margin: 0;
    }
  }
}
.pressroom_list_page_content {
  padding-top: rfs-value(60px);
  @include media-breakpoint-down(lg) {
    padding-top: rfs-value(30px);
  }

  .article_wrapper {
    border-top: 1px solid $nikon-light-grey-border;

    &:first-child {
      border: none;
    }

    .article {
      text-decoration: none;
      cursor: pointer;
      border-top: 1px solid $nikon-light-grey-border;
      padding: rfs-value(20px 0);
      color: $nikon-dark-grey;
      .article_inner_wrapper {
        display: grid;
        grid-template-columns: 4fr 1fr;
        @include media-breakpoint-down(xl) {
          grid-template-columns: 1fr;
        }

        .article_info {
          padding: rfs-value(45px) 10% rfs-value(45px) 0;
          @include media-breakpoint-down(lg) {
            padding: rfs-value(30px) rfs-value(15px);
          }
          .date {
            text-transform: uppercase;
            letter-spacing: 4px;
            font-size: rfs-value(14px);
            font-weight: $font-weight-bold;
          }

          .title {
            font-weight: $font-weight-light;
            margin-bottom: rfs-value(30px);
          }

          .article_text {
            font-size: rfs-value(14px);
          }
        }
        .image_wrapper {
          display: flex;
          flex-flow: row;
          align-items: center;
          @include media-breakpoint-down(xl) {
            display: none;
          }
        }
      }
    }
  }
  .pagination {
    margin-top: rfs-value(60px);
    @include media-breakpoint-down(sm) {
      margin-top: rfs-value(30px);
    }
  }
}
.additional_content_outer_wrapper {
  padding: rfs-value(60px) 0;
  .additional_content_header {
    color: $nikon-black;
    font-weight: $font-weight-light;
    width: 100%;
    text-align: center;
    font-size: rfs-value(48px);
  }

  .additional_content_wrapper {
    display: flex;
  }
}
