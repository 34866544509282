@import 'styles/variables';
@import 'bootstrap/scss/mixins';

.accessoriesContainer {
  @include media-breakpoint-up(md) {
    max-width: 750px;
  }

  @include media-breakpoint-up(lg) {
    max-width: 970px;
  }
}

ul.row {
  padding-top: 1rem;
  padding-left: unset;
}
