@import '../../../../../styles/variables';
@import 'bootstrap/scss/mixins';

.supportTab {
  section {
    margin-bottom: 30px;

    .title {
      margin: 0 0 11px;
      padding: 0 15px 10px;
      border-bottom: 1px solid #bbb;
      font-family: $font-family-generic;
      font-size: rfs-value(20px);
      line-height: rfs-fluid-value(24px);
      color: #333;
    }

    .content {
      padding: 0 15px;
      font-size: rfs-value(12px);
      line-height: rfs-fluid-value(21.05px);

      p {
        margin: 0;
      }

      .link {
        display: flex;
        align-items: center;
        gap: 4px;
        font-weight: 700;
        color: $nikon-link-bold;

        svg {
          height: 14px;
        }
      }
    }
  }
}
