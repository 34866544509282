@import '../../../../../styles/variables';

.banner {
  background-color: $nikon-checkout-footer-bg;
  padding: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 15px;
  font-size: 14px;
  position: relative;
  z-index: 1;
}

.banner-text {
  display: flex;
  align-items: center;
  gap: 8px;
}

.banner-icon {
  width: 14px;
  height: 14px;
  color: white;
  background: $nikon-grey;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
