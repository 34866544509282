@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.lne-filters {
  @include media-breakpoint-down(md) {
    button {
      min-width: 110px;
      font-size: rfs-value(14px);
    }
  }

  :global(.bi-sliders) {
    margin-left: rfs-value(4px);
  }
}

.lne-filters-offcanvas {
  :global {
    .offcanvas-body {
      padding-top: 0;
    }
    .customFormCheckbox {
      margin: rfs-value(10px 0);
      font-size: rfs-value(16px);

      label:before {
        position: relative;
        top: -2px;
      }
    }
  }
}
