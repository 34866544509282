@import '../../../../../styles/variables';
@import 'bootstrap/scss/mixins';

.productInfo {
  display: flex;
  flex-direction: column;
  padding: rfs-value(15px);
  margin: 0;
  border-top: 1px solid $nikon-light-grey;
  font-size: rfs-value(12px);
  background: $nikon-white;
  white-space: break-spaces;

  &.isMobile {
    background: #000;
    border: 0;
  }

  @include media-breakpoint-up(md) {
    border: 0;
    background: #000;
    color: $body-contrast-color;
  }

  .productName {
    margin: 0;
    font-size: rfs-value(14px);
    font-weight: $font-weight-normal;
    line-height: rfs-value(19px);
    color: $nikon-dark-grey;

    @include media-breakpoint-up(md) {
      font-size: rfs-value(16px);
      font-weight: $font-weight-bold;
      line-height: rfs-value(22px);
      color: $body-contrast-color;
    }
  }

  .productPrice,
  .ratingSection {
    margin: 0;
    font-size: rfs-value(14px);
    font-weight: $font-weight-bold;
    color: $body-contrast-color;
    line-height: 1.7;
  }
  .ratingSection {
    margin-top: 5px;
    padding: 0 !important;

    div {
      cursor: pointer;
    }
    span {
      text-transform: none;
      letter-spacing: 0.1px;
      color: $nikon-white-color;
      font-weight: $font-weight-normal;
      margin-bottom: 4px;
    }
  }
  .ratingSection:global(.nav-link) {
    &:active,
    &:focus,
    &:hover,
    &:global(.active) {
      text-decoration: none;
    }
  }
}
