@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.search_wrapper {
  display: flex;

  .search_icon {
    display: inline-flex;
    width: rfs-value(36px);
    border: 1px solid $nikon-grey;
    border-radius: rfs-value(90px) 0 0 rfs-value(90px);
    border-right: none;
    color: $nikon-grey;
    background: $nikon-white;
    height: rfs-value(36px);
    padding: 3px;

    svg {
      height: rfs-value(25px);
      width: rfs-value(25px);
      border: none;
      margin: rfs-value(2px) 0 0 rfs-value(2px);
    }
  }

  &.filter {
    .search_icon:hover {
      background: $nikon-yellow;
      cursor: pointer;

      svg {
        background: $nikon-yellow;
      }
    }

    &:focus-within {
      .search_icon, .search_icon svg {
        background: $nikon-yellow;
      }
    }
  }
  .search_input {
    display: inline-flex;
    color: $nikon-grey;
    min-width: rfs-value(250px);
    width: 100%;
    border: 1px solid $nikon-grey;
    border-radius: 0 rfs-value(90px) rfs-value(90px) 0;
    border-left: none;
    line-height: rfs-value(18px);
    font-size: rfs-value(16px);
    font-weight: $font-weight-bold;
    padding: rfs-value(8px) rfs-value(15px) rfs-value(8px) rfs-value(20px);
    height: 36px;
    &:first-child {
      border-left: 1px solid  $nikon-grey;
      border-radius: rfs-value(90px);
    }

    &::placeholder {
      color: $nikon-ada-grey;
      font-size: rfs-value(12px);
      font-family: Times, serif;
      font-style: italic;
      font-weight: 400;
      text-transform: capitalize;
    }
  }

  .clear_search {
    display: none;
    position: absolute;
    width: rfs-value(38px);
    line-height: rfs-value(38px);
    text-align: center;
    font-size: rfs-value(38px);
    font-weight: $font-weight-semibold;
    color: $nikon-ada-grey;

    &:hover {
      cursor: pointer;
    }

    &.show {
      display: flex;
    }
  }

}
