@import '../../../../../../styles/variables';
@import 'bootstrap/scss/mixins';

.at-a-glance {
  --container-padding: 30px;
  margin-bottom: rfs-value(40px);
  padding: 0 var(--container-padding);

  &:has(li) {
    background-color: $nikon-light-grey;
    padding-bottom: var(--container-padding);
  }

  @include media-breakpoint-up(md) {
    --container-padding: 60px;
  }

  h2 {
    margin-top: rfs-value(45px);
    padding-bottom: rfs-value(45px);
    font-size: rfs-value(36px);

    @include media-breakpoint-up(md) {
      margin-top: rfs-value(60px);
      font-size: rfs-value(48px);
    }
  }

  .specs-list {
    column-count: 1;
    column-gap: rfs-value(60px);
    padding: 0;
    margin: 0;

    &-item {
      display: flex;
      gap: rfs-value(30px);
      margin-bottom: rfs-value(22px);
      break-inside: avoid-column;
      font-size: rfs-value(14px);

      &-icon-container {
        height: rfs-value(36px);
        width: rfs-value(42px);
        min-width: rfs-value(42px);
        margin-top: 5px;
      }

      &-icon {
        width: 100%;
        height: 100%;
        position: relative;
      }

      &-texts-container {
        display: flex;
        flex-direction: column;
      }

      &-title {
        color: $nikon-dark-grey;
        font-weight: $font-weight-bold;
        margin-bottom: rfs-value(4px);
      }

      &-text {
        color: $nikon-black;
        word-break: break-word;
      }
    }

    @include media-breakpoint-up(md) {
      column-count: 2;

      &-item {
        font-size: rfs-value(16px);
      }
    }
    @include media-breakpoint-up(lg) {
      column-count: 3;
    }
  }
}
