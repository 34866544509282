@import '../../../../styles/variables';
@import 'bootstrap/scss/mixins';

.browSubNav {
  :global(.subnav-item) {
    @include media-breakpoint-up(md) {
      display: block;
      text-align: right !important;

      &:first-child {
        :global(.item-link) {
          margin-top: 0;
        }
      }

      &:last-child {
        :global(.item-link) {
          margin-bottom: 15px;
        }
      }
    }

    :global(.item-link) {
      @include media-breakpoint-up(md) {
        font-size: rfs-value(11px);
        padding: 12px 30px 13px;
      }
    }

    &:hover {
      background-color: #000;
    }
  }
}
