@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

    .filter_dropdown {
      display: flex;
      justify-content: right;
      align-content: center;
      position: relative;
      width: 100%;
      .filter_dropdown_toggle {
        display: flex;
        justify-content: left;
        align-content: center;
        min-width: rfs-value(150px);
        border-radius: 2px;
        font-size: rfs-value(16px);
        border: 1px solid $nikon-grey;
        text-transform: capitalize;
        width: 100%;
        &.dark, &.dark span {
          background: $nikon-black;
          color: $nikon-light-grey;
          &:after {
            border-color: $nikon-light-grey;
          }
        }
        &.light, &.light span {
          background: $nikon-white;
          color: $nikon-grey;
          &:after {
            border-color: $nikon-grey;
          }
        }
        span {
          display: inline-flex;
          min-width: fit-content;
          width: 100%;
        }
        &:after {
          display: inline-flex;
          border-color: $nikon-grey;
          margin-top: 5px;
          margin-left: rfs-value(25px);
          height: 8px;
        }
      }
      .filter_dropdown_menu {
        padding: 5px 0;
        border: 1px solid $nikon-grey;
        border-radius: 0 0 5px 5px;
        min-width: rfs-value(150px);
        &.fill {
          width: 100%;
        }
        &.fit {
          width: fit-content;
          @include media-breakpoint-down(sm){
            width: 100%;
          }
        }
        &.dark {
          background: $nikon-black;
          color: $nikon-light-grey;
        }
        &.light {
          background: $nikon-white;
          color: $nikon-grey;
        }
      }
      .filter_dropdown_item {
        color: $nikon-grey;
        background: $nikon-white;
        width: fit-content;
        text-transform: capitalize;
        display: block;
        text-decoration: none;
        position: relative;
        min-width: rfs-value(148px);
        &.fill {
          width: 100%;
        }
        &.fit {
          width: fit-content;
          @include media-breakpoint-down(sm){
            width: 100%;
          }
        }
        &.dark {
          background: $nikon-black;
          color: $nikon-light-grey;
        }
        &.light {
          background: $nikon-white;
          color: $nikon-grey;
        }
        &.active {
          &.dark {
            background: $nikon-grey;
            color: $nikon-light-grey;
          }
          &.light {
            background: $nikon-light-grey;
            color: $nikon-grey;
          }
        }
      }
    }
