@import 'styles/variables';
@import 'bootstrap/scss/mixins';

.ratings-reviews-msg-heading {
  color: inherit;
  font-weight: 600;
  font-family: $headings-font-family;
  text-align: left !important;
  font-size: rfs-value(28px) !important;
  margin-bottom: 10px !important;
  margin-top: 20px !important;
  padding: 0 !important;
}
.ratings-reviews-msg-desc {
  font-family: $headings-font-family;
  font-size: rfs-value(12px) !important;
  margin: 0 0 10px !important;
  line-height: 1.7;
  color: $body-color;
}
