@import 'styles/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/vendor/rfs';

.lne-category-bar-and-menu {
  padding: 0;
  background: black;
  border-bottom: 1px solid rgba($nikon-yellow, 0.25);
  align-items: center;
  width: 100%;
  z-index: 6;
  margin-bottom: rfs-value(20px);

  .navList {
    padding: 0;
    margin: rfs-value(0 0 15px);

    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: center;
      align-content: center;
      margin: 0;
      width: 100%;
    }
  }

  :global(.nav-link),
  :global(.navbar-toggler) {
    font-weight: $font-weight-bold;
    font-size: rfs-value(12px);
    line-height: 1.7;
    letter-spacing: rfs-value(1.2px);
    text-transform: uppercase;
    padding: rfs-value(13px 15px);
    color: white;
    text-decoration-color: transparent;
    transition: text-decoration 0.2s ease-in;

    &:active,
    &:focus {
      box-shadow: none;
    }
  }

  :global(.nav-link) {
    font-weight: 700;
    letter-spacing: 4px;
    padding: rfs-value(15px 20px 9px);
    text-align: center;
    font-size: rfs-value(14px);
    line-height: rfs-value(21px);
    display: flex;
    align-items: center;

    @include media-breakpoint-up(md) {
      font-size: rfs-value(12px);
      line-height: rfs-value(18px);
    }

    @include media-breakpoint-up(lg) {
      font-size: rfs-value(14px);
      line-height: rfs-value(21px);
    }

    span {
      display: inline-block;

      &:after {
        content: '';
        border-bottom: 2px solid transparent;
        display: block;
        margin-left: rfs-value(-1px);
        margin-top: rfs-value(4px);
        position: relative;
        width: 100%;
      }

      &:hover,
      &:focus {
        &:after {
          border-color: $nikon-yellow;
        }
      }
    }
    &:global(.active) span:after {
      border-color: $nikon-yellow;
    }
    svg {
      margin-top: -8px;
    }
  }

  // START OF MOBILE MENU STYLES
  .mobileMenuButton {
    background: 0 0;
    border: none;
    width: 100%;
    text-align: left;
    position: relative;
    border-radius: 0;
  }

  .browMobileIcon {
    opacity: 1;
    position: absolute;
    margin-top: rfs-value(1px);
    padding-top: rfs-value(4px);
    right: 15px;
    transform: translateY(-50%);
    transition: opacity 350ms ease-out;
    will-change: opacity;
    vertical-align: middle;
    font-family: $icon-font;
    font-weight: $font-weight-normal;
    text-decoration: none;
    white-space: nowrap;
  }

  :global(.navbar-toggler) {
    .mobile-menu-navdown-icon {
      top: 50%;

      &::before {
        content: '␡';
      }
    }
  }

  :global(.navbar-toggler.collapsed) {
    .mobile-menu-navdown-icon {
      &::before {
        content: url(/icons/downArrowWhite.svg);
      }
    }
  }
}

.searchDesktop {
  display: none;

  @include media-breakpoint-up(md) {
    display: block;
  }
}

.search-bar-container {
  padding: rfs-value(15px);
  margin-bottom: rfs-value(10px);

  @include media-breakpoint-up(md) {
    display: flex;
    width: 100%;
    background: $nikon-black;
    position: absolute;
    justify-content: center;
    z-index: 0;
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
    visibility: hidden;
    padding: 0;
    margin: 0;

    .search-bar {
      max-width: 900px;
      width: 100%;
    }
    .close {
      width: 40px;
      height: 54px;
      background-image: url(/icons/closeIconWhite.svg);
      background-repeat: no-repeat;
      background-size: 20px;
      background-position: center;
      cursor: pointer;
    }

    &:global(.active) {
      opacity: 1;
      z-index: 99;
      visibility: visible;
      padding: rfs-value(6px 0 7px);

      & + ul {
        opacity: 0;
      }
    }
  }
}
