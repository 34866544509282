@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.lne-category-page-main-content {
  max-width: 970px;
  margin: rfs-value(60px auto 100px);

  & > :global(.container) {
    padding: rfs-value(0 30px);
  }
  h1 {
    font-size: rfs-value(48px);
    line-height: 1.2;
    margin-bottom: rfs-value(40px);
  }
}

.lne-filter-pagination-container {
  margin: rfs-value(40px auto);
  padding: rfs-value(25px 0);
  border-top: 1px solid $nikon-light-grey-border;
  border-bottom: 1px solid $nikon-light-grey-border;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
}

.pagination {
  @include media-breakpoint-down(sm) {
    width: 100%;
  }

  &.pagination-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: rfs-value(30px 0 60px);
  }
}
