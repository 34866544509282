@import '../../../styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.cartBanner {
  @include media-breakpoint-up(lg) {
    margin: rfs-value(24px auto -36px);
    padding-left: calc(var(--bs-gutter-x));
    padding-right: calc(var(--bs-gutter-x));
  }

  p:last-of-type {
    margin-bottom: 0;
  }
  a {
    text-decoration: none;
  }
}
