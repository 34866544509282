@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.service_articles_list {
  margin-top: rfs-value(50px);
  .page_title {
    border-bottom: 1px solid $nikon-light-grey-border;
    padding-bottom: rfs-value(25px);
  }
  ul {
    padding: 0;
  }
}

.article {
  display: grid;
  cursor: pointer;
  grid-template-columns: 1fr 2fr;
  border-top: 1px solid $nikon-light-grey-border;
  padding: rfs-value(20px 0);
  //width: rfs-value(750px);
  min-height: rfs-value(150px);
  align-items: center;

  .image_wrapper {
    position: relative;
    margin: 0 rfs-value(15px);
    min-height: rfs-value(150px);
    display: flex;
    justify-content: center;

    img {
      max-width: rfs-value(177px);

      @include media-breakpoint-down(md) {
        max-height: rfs-value(120px);
        width: 100%;
        object-fit: contain;
      }
    }

    .classification_label {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
      background-color: $nikon-light-grey;
      text-transform: uppercase;
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }

  h3 {
    font-size: rfs-value(24px);
  }

  &:first-child {
    margin-top: rfs-value(25px);
    border-top: none;
  }

  .date_block {
    text-transform: uppercase;
    letter-spacing: 4px;
    font-size: rfs-value(12px);
    font-weight: $font-weight-bold;

    .updated_badge {
      background-color: $nikon-yellow;
      color: $nikon-dark-grey;
      font-size: rfs-value(12px);
      border: 1px solid $nikon-yellow-hover;
      padding: 4px;
      font-weight: $font-weight-bold;
      margin-right: rfs-value(10px);
      letter-spacing: initial;
    }

    .original_date {
      .original_date_label {
        text-transform: capitalize;
        font-style: italic;
        margin-right: rfs-value(10px);
        font-weight: $font-weight-normal;
      }
    }
  }
}
