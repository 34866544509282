@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.lne-article-page-main-content {
  max-width: 970px;
  margin: 0 auto;
}

.lne-article-body {
  margin-top: rfs-value(40px);
  padding: rfs-value(0 15px);
}

.difficulty {
  margin-bottom: rfs-value(40px);
  padding: rfs-value(12px 20px 12px 0);
  background: $nikon-checkout-footer-bg;
  color: $nikon-dark-grey;
  display: inline-block;
  font-size: rfs-value(12px);
  line-height: 1.5;
  font-weight: 700;
  letter-spacing: 2px;
  position: relative;
  text-transform: uppercase;

  &:before {
    background: $nikon-checkout-footer-bg;
    color: $nikon-dark-grey;
    content: '';
    height: 100%;
    position: absolute;
    right: 100%;
    top: 0;
    width: 200px;
  }

  &.advanced,
  &.advanced:before {
    background: $nikon-black;
    color: $nikon-white;
  }

  &.intermediate,
  &.intermediate:before {
    background: $nikon-yellow;
    color: $nikon-dark-grey;
  }
}

.title {
  margin-bottom: rfs-value(30px);

  @include media-breakpoint-up(md) {
    margin-bottom: rfs-value(30px);
  }

  h1 {
    font-size: rfs-value(48px);
    line-height: 1.2;
    margin: rfs-value(0 0 10px);
  }

  .subtitle {
    font-size: rfs-value(30px);
    font-weight: 700;
    line-height: 1.2;
    margin: 0;
  }
}

.byline {
  margin-top: rfs-value(20px);
  font-size: rfs-value(14px);
  line-height: 1.5;
  letter-spacing: rfs-value(4px);

  em {
    font-family: $font-family-serif;
    margin-right: rfs-value(4px);
  }
  div {
    display: inline;
  }
  a {
    text-transform: uppercase;
    font-weight: 700;
    color: $nikon-black;
  }
}

.lne-article-contributor-footer {
  .contributor {
    border: 1px solid $nikon-light-grey-border;
    padding: rfs-value(40px);
    border-radius: 15px;
  }
  .contributor-image {
    display: flex;
    justify-content: center;
    margin-bottom: rfs-value(30px);

    @include media-breakpoint-up(md) {
      margin-left: rfs-value(-40px);
    }
  }
  .byline {
    margin: 0;

    em {
      display: block;
      margin: rfs-value(0 0 10px);
    }
    div {
      display: block;
    }
  }
  .ambassador-logo {
    display: block;
    margin: rfs-value(20px 0 15px);
    max-height: 40px;
    max-width: 100%;
  }
  p {
    font-size: rfs-value(12px);
    line-height: 1.5;
    margin-bottom: rfs-value(10px);
  }
  .link {
    font-size: rfs-value(12px);
    line-height: 1.5;
    color: $nikon-dark-grey;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 700;

    &:hover {
      color: $nikon-dark-grey;
      text-decoration: underline;
    }

    .icon {
      display: inline-block;
      margin-left: rfs-value(5px);
      font-size: 40px;
      position: relative;

      &::before {
        content: '';
        width: 11px;
        height: 11px;
        background: url(/icons/rightArrowYellow.svg) center no-repeat;
        background-size: contain;
        position: absolute;
        bottom: -1px;
        left: 0;
      }
    }
  }
}

.lne-article-related-products {
  margin: rfs-value(40px 0);

  @include media-breakpoint-up(lg) {
    margin: rfs-value(60px 0);
    padding: rfs-value(0 15px);
  }

  h2 {
    margin-bottom: rfs-value(40px);
    text-align: center;
    font-size: rfs-value(30px);
    line-height: 1.2;
    padding: rfs-value(0 15px);

    @include media-breakpoint-up(lg) {
      margin-bottom: rfs-value(60px);
    }
  }
  :global(.slick-slider) {
    max-width: 315px;
    margin: 0 auto;

    @include media-breakpoint-up(md) {
      max-width: 630px;
      padding: 0 !important;
    }
    @include media-breakpoint-up(lg) {
      &:not(:global(.slides-2)) {
        max-width: unset;
      }
    }
  }
  :global(.slick-slide) > div > div {
    padding: rfs-value(0 15px);
  }
  .single-product {
    max-width: 345px;
    padding: 0;
    margin: 0 auto;

    & > div {
      padding: 0 15px;
    }
  }
}

.lne-content-block {
  margin-bottom: rfs-value(30px);

  @include media-breakpoint-up(md) {
    margin-bottom: rfs-value(30px);
  }

  :global(.one-to-four-column) {
    margin: 0;

    :global(.container) {
      padding: 0;

      :global(.row) {
        padding: 0;
      }

      @include media-breakpoint-down(md) {
        :global(.col-sm-12) {
          padding: 0;
        }
      }
    }
  }
}

.lne-rich-text-column {
  :global(.lne-drop-case),
  :global(.drop-case):first-letter {
    float: left;
    font-size: rfs-value(72px);
    font-weight: 400;
    line-height: 0.6;
    margin: rfs-value(8px 8px 8px 0);
  }
  &:global(.bg-grey) {
    background: $nikon-checkout-footer-bg;
    border: 1px solid $nikon-light-grey;
    border-radius: 15px;
    padding: rfs-value(35px 30px 20px);
    word-break: break-word;
    margin: rfs-value(0 15px);
    width: calc(100% - 30px);
  }
  ol {
    list-style: decimal;
    padding-left: rfs-value(18px);
  }
  ul {
    list-style: disc;
  }
  li p {
    margin-bottom: 0;
  }
}

.lne-quote-content {
  border-bottom: 1px solid $nikon-light-grey;
  border-top: 1px solid $nikon-light-grey;
  padding: 40px 5%;
  position: relative;
  text-align: center;

  @include media-breakpoint-up(md) {
    padding: 40px 15%;
  }

  &:before,
  &:after {
    background: $nikon-white;
    font-family: $font-family-serif;
    font-size: rfs-value(86px);
    left: 50%;
    line-height: 0;
    padding: rfs-value(35px 20px 0);
    position: absolute;
    transform: translate(-50%, -50%);
  }
  &:before {
    content: '“';
    top: 0;

    @include media-breakpoint-up(md) {
      top: 50%;
      left: 10%;
    }
  }
  &:after {
    content: '”';
    top: 100%;

    @include media-breakpoint-up(md) {
      top: 50%;
      left: 90%;
    }
  }

  blockquote {
    font-size: rfs-value(30px);
    font-weight: 700;
    line-height: 1.2;
    padding: rfs-value(10px 20px);
  }
}

.lne-caption {
  margin-top: rfs-value(10px);
  width: 100%;

  .author {
    font-size: rfs-value(14px);
    text-align: right;
    margin-bottom: rfs-value(10px);
  }
  .caption {
    font-size: rfs-value(12px);
    line-height: 1.5;
    margin: 0;
  }
  .caption-content {
    display: inline;

    p:last-of-type {
      margin-bottom: 0;
    }
  }
}

.lne-image-display {
  margin: 0 !important;

  :global(.slick-slider) {
    padding: rfs-value(30px 0) !important;
    border-bottom: 1px solid $nikon-light-grey;
    border-top: 1px solid $nikon-light-grey;

    @include media-breakpoint-up(md) {
      padding: rfs-value(40px 0) !important;
    }
  }

  @include media-breakpoint-down(md) {
    width: 100%;
    flex: 0 0 100%;

    & + & {
      margin-top: rfs-value(30px) !important;
    }
  }
}

.lne-image-display-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .img-wrap {
    .img-and-zoom-icon {
      position: relative;
      background: 0 0;
      border: none;
      display: table-cell;
      margin: 0 auto;
      max-width: 100%;
      padding: 0;
      width: auto;
    }

    .lightbox & {
      cursor: pointer;
    }

    img {
      max-height: 660px;
    }

    &:hover :global(.ss-icon) {
      background: rgba($nikon-black, 0.8);
    }
  }
  :global(.ss-icon) {
    position: absolute;
    bottom: rfs-value(10px);
    right: rfs-value(10px);
    background: rgba($nikon-black, 0.6);
    color: $nikon-white;
    display: inline-block;
    line-height: 0;
    padding: rfs-value(22px 12px 18px);
  }
}
