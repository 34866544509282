@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.glossary_page {
  background: $nikon-checkout-light-bg;
  border: solid $nikon-border-light-grey;
  border-width: 1px 0 ;
  padding:  rfs-value(20px) 0;
}

.scroll_to_anchor {
  scroll-padding-top: 160px;
}

.glossary_header {
  position: sticky;
  top: 0;
  background: $nikon-checkout-light-bg;
  border-bottom: 1px solid $nikon-light-grey;
}

.glossary_nav {
  margin:  rfs-value(10px) auto  rfs-value(30px);
  text-align: center;
  display: flex;
  flex-flow: row wrap;
  gap: 5px;
  width: fit-content;
  align-content: center;
  align-items: center;

  @include media-breakpoint-down(lg) {
    width: 500px;
    margin: rfs-value(10px) auto rfs-value(30px);
  }

  @include media-breakpoint-down(md) {
    width: 100%;
    padding: 0 rfs-value(25px);
  }

  .nav_item, .nav_item_number {
    color: $nikon-ada-grey;
    background: $nikon-white;
    border: 1px solid $nikon-border-light-grey;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    display: inline-block;
    font-size:  rfs-value(18px);
    line-height:  rfs-value(30px);
    height:  rfs-value(30px);
    text-align: center;
    text-decoration: none;
    vertical-align: top;
    width:  rfs-value(30px);
    &.active, &:hover:not(:disabled) {
      background: $nikon-yellow-hover;
      color: $nikon-black;
      cursor: pointer;
    }
    &:disabled {
      color: $nikon-light-grey-border;
      border: 1px solid $nikon-light-grey-border;
    }
  }
  .nav_item_number {
    position: relative;
    font-size:  rfs-value(14px);
   span {
     position: relative;
     top: -6px;
     left: 1px
   }
    &:before {
      content: '\0031';
      vertical-align: super;
      position: relative;
      top: -6px;
      left: -1px;
    }
    &::after {
      content: '\0033';
      vertical-align: sub;
      position: relative;
      bottom: 2px;
      right: -4px;
    }
  }
}

.glossary_list {
  border: 1px solid $nikon-light-grey;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0;
  padding: 0 0 0  rfs-value(15px);
  background: $nikon-white;

  .glossary_term {
    border-top: 1px solid $nikon-light-grey;
    padding:  rfs-value(15px)  rfs-value(10px);

    &:first-child {
      border-top: none;
    }

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .glossary_term_label {
      color: $nikon-link-bold;
      cursor: pointer;
      font-size:  rfs-value(16px);
      line-height:  rfs-value(18px);
      font-weight: $font-weight-normal;
    }

    &.active + dd {
      display: block;
    }
  }


  .glossary_list.active + dd {
    display: block;
  }

  dd.glossary_def {
    display: none;
    font-size: 12px;
    color: $nikon-ada-grey;
    padding: 0  rfs-value(100px) 0  rfs-value(10px);
    @include media-breakpoint-down(md) {
      padding: 0;
    }
    .glossary_def_text, .glossary_def_image {
      margin: 0 rfs-value(15px);
    }
    .glossary_def_text {
      width: calc(100% - 140px);
      vertical-align: middle;
      @include media-breakpoint-down(md) {
        display: block;
        width: 90%;
        margin: 0 auto;
      }
    }
  }
}
.search_area {
  color: $nikon-dark-grey;
  font-size:  rfs-value(26px);
  padding:  rfs-value(22px)  rfs-value(15px);
  text-align: left;
  @include media-breakpoint-down(lg) {
    text-align: center;
  }


  .pre_label {
    display: inline-block;
    height:  rfs-value(36px);
    line-height:  rfs-value(34px);
    padding: 0;
    vertical-align: top;
    margin-right:  rfs-value(15px);
  }

  .search_box {
    margin: 0  rfs-value(12px);
    position: relative;
    display: inline-block;
    height:  rfs-value(38px);
    line-height:  rfs-value(34px);
    padding: 0;
    vertical-align: top;


    .search_input {
      border-radius: 2px 2px 2px 2px;
      border: 1px solid $nikon-light-grey;
      height: 100%;
      position: relative;
      z-index: 2;
      background: $nikon-white;
      max-width:  rfs-value(400px);
      @include media-breakpoint-down(sm) {
        z-index: 3;
      }
      input {
        border: 0;
        color: $nikon-ada-grey;
        font-size:  rfs-value(24px);
        font-weight: $font-weight-normal;
        width: rfs-value(360px);
        @include media-breakpoint-down(md) {
          width: auto;
        }
        padding-left: 6px;
        line-height:  rfs-value(28px);
        vertical-align: top;
        height:  rfs-value(36px);
        &:focus {
          outline-color: $input-focus-border-color !important;
          outline-offset: -2px;
        }
      }

      .search_icon {
        background: white url(/icons/searchIcon.svg) no-repeat center;
        display: inline-block;
        height: 100%;
        width:  rfs-value(38px);
        border-left: 1px solid $nikon-light-grey;
        border-radius: 2px 2px 0 0;
        position: absolute;
        top: 0;
        right: 0;
      }

      .clear_search {
        display: none;
        position: absolute;
        top: 0;
        right:  rfs-value(40px);
        width:  rfs-value(38px);
        line-height:  rfs-value(38px);
        text-align: center;
        font-size:  rfs-value(38px);
        font-weight: $font-weight-semibold;
        color: $nikon-ada-grey;
      }
    }
    &:focus-within {
      .search_icon {
        background-color: $nikon-yellow-hover;
        color: $nikon-black;
      }
    }
    .auto_complete {
      background: $nikon-white;
      border: 1px solid $nikon-light-grey;
      max-height:  rfs-value(450px);
      width:  rfs-value(420px);
      position: absolute;
      top:  rfs-value(-10px);
      left:  rfs-value(-10px);
      padding-top:  rfs-value(50px);
      display: none;
      @include media-breakpoint-down(sm) {
        z-index: 2;
        width: rfs-value(326px);
      }
      .auto_complete_count {
        text-align: right;
        font-size:  rfs-value(12px);
        line-height:  rfs-value(18px);
        display: inline-block;
        width: 100%;
        padding-right:  rfs-value(10px);
      }

      .auto_complete_list {
        background: $nikon-white;
        border: 1px solid $nikon-light-grey;
        border-radius: 0 0 2px 2px;
        font-size:  rfs-value(12px);
        max-height:  rfs-value(400px);
        width: 100%;
        overflow-y: auto;
        padding: 0;
        margin-bottom: 0;

        li {
          border-top: 1px solid $nikon-light-grey;

          &:first-child {
            border-top: none;
          }
          &:hover, &:focus-within {
            background: $nikon-light-grey;
            cursor: pointer;
            a {
              color: $nikon-black;
              outline: none;
            }
          }
        }

        a {
          color: $nikon-ada-grey;
          text-decoration: none;
          font-size:  rfs-value(16px);
          line-height:  rfs-value(18px);
          margin:  rfs-value(5px)  rfs-value(10px);
        }
      }
    }
    &.has_results:focus-within {
      .auto_complete {
        display: block;
      }

      .clear_search {
        display: block;
      }
    }
  }
}
