@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

$border: 2px $nikon-light-grey solid;

.container {
  display: grid;
  grid-template-columns: unset;
  grid-template-rows: auto 1fr;
  grid-auto-flow: column;
  gap: 1rem;
  text-decoration: none;
  padding: rfs-value(35px 0);
  color: inherit;
  font-size: 0.75rem;
  font-family: $font-family-generic;
  line-height: 1.1;

  @include media-breakpoint-down(md) {
    border-bottom: $border;
    grid-template-rows: unset;
    grid-template-columns: 1fr 50%;
    grid-auto-flow: row;
    padding: rfs-value(30px 0);
  }

  &:hover .product-image {
    border-bottom-color: $nikon-yellow;
  }


  & .description {
    font-weight: 600;
    min-height: rfs-value(67px);

    & > .title {
      color: $nikon-dark-grey;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    & > .price {
      margin-top: 0.5rem;
      font-family: $font-family-base;
      font-weight: $font-weight-bold;
      line-height: rfs-value(20px);

      & .oldPrice {
        color: $nikon-dark-grey;
        text-decoration: line-through;
        font-size: rfs-value(11px);
      }

      & .save-money {
        display: block;
        color: $nikon-blood-orange;
      }
    }

    @include media-breakpoint-down(md) {
      min-height: rfs-value(30px);
    }
  }

  .max-quantity-meet {
    font-style: italic;
    color: $nikon-dark-grey;
  }

  & .button {
    width: 100%;
    max-width: rfs-value(150px);
    padding: 0.5rem;

    &.disabled {
      background-color: $nikon-light-grey;
    }
  }

  & .button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: rfs-value(32px);

    @include media-breakpoint-down(md) {
      justify-content: start;
      align-items: start;
    }
  }

  & .product-image {
    border-bottom: none;

    @include media-breakpoint-up(md) {
      border-bottom: 1px solid #ccc;
      min-height: rfs-value(85px);
      padding-bottom: rfs-value(10px);
      text-align: center;
    }
  }

  & .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: rfs-value(0 10px);

    & .img {
      max-width: 100%;
      width: rfs-value(87px);
    }
  }

  & .content {
    display: flex;
    flex-direction: column;
    margin-top: rfs-value(-5px);

    @include media-breakpoint-down(md) {
      justify-content: center;
    }
  }

  .product-cta {
    width: 100%;
    min-width: rfs-value(140px);
    margin: rfs-value(10px 0);
    padding: rfs-value(10px);
    font-size: rfs-value(14px);
  }

  .add-to-cart-error {
    font-size: rfs-value(14px);
    line-height: rfs-value(20px);
    color : $nikon-red;
  }
}
