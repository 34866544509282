@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.press_article_page {
  table {
    border: 1px outset grey;
    border-collapse: separate;
    border-spacing: 0;

    td {
      border: 1px inset grey;
      padding: rfs-value(3px);
      vertical-align: middle;
    }
    p {
      margin: rfs-value(0 0 10px);
    }
    img {
      margin: 0 auto;
    }
  }

  .article_header {
    display: flex;
    padding: rfs-value(20px) 0;
    @include media-breakpoint-down(sm) {
      flex-flow: row;
    }
    .button_wrapper {
      display: inline-flex;
      justify-content: right;
      align-items: center;
      @include media-breakpoint-down(sm) {
        width: fit-content;
        justify-content: left;
      }
      .contact_us_btn {
        text-transform: capitalize;
        font-size: rfs-value(12px);
        background: $nikon_dark-grey;
        border-color: $nikon-dark-grey;
        padding: rfs-value(3px) rfs-value(18px);
        &:hover {
          color: $nikon-black;
          background: $nikon-light-grey;
          border-color: $nikon-dark-grey;
        }
      }
    }
    .search_wrapper {
      display: inline-flex;
      @include media-breakpoint-down(sm) {
        width: fit-content;
      }
      .pressroom_search {
        justify-content: right;
        align-items: center;

        input {
          height: 24px;
        }
      }
    }
  }
  .article_main_content {
    color: $nikon-dark-grey;
    .article_main_content_date {
      display: flex;
      justify-content: left;
      font-size: rfs-value(12px);
      font-weight: $font-weight-normal;
    }
    .article_main_content_title {
      font-size: rfs-value(30px);
      line-height: rfs-value(36px);
      margin-top: rfs-value(20px);
    }
    .article_main_content_subtitle {
      font-size: rfs-value(18px);
      color: $nikon-grey;
    }
    .article_main_content_location {
      text-transform: uppercase;
      font-size: rfs-value(12px);
      line-height: rfs-value(20px);
      margin-bottom: 0;
    }
    .article_main_content_body {
      font-size: rfs-value(12px);
      line-height: rfs-value(20px);

      ul, ol {
        list-style: revert;
      }
    }
  }
  .gallery_wrapper {
    margin: rfs-value(10px) 0;

    .gallery_title {
      font-size: rfs-value(18px);
      font-weight: $font-weight-medium;
      line-height: rfs-value(20px);
      color: $nikon-dark-grey;
    }

    .gallery_images {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: rfs-value(5px);

      .gallery_image {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 60px;
        width: 60px;
        border: 1px solid $nikon-dark-grey;
        object-fit: contain;
        overflow: hidden;
        padding: 2px;

        &:hover {
          border-color: $nikon-yellow-hover;
        }
      }
    }
    .download_btn_wrapper {
      display: flex;
      width: 100%;
      justify-content: left;
      padding: rfs-value(5px) 0;
      .download_btn {
        background: $nikon-yellow;
        color: $nikon-black;
        padding: rfs-value(2px) rfs-value(5px);
        margin: rfs-value(5px) 0 0;
        text-transform: capitalize;
        border: none;
        font-size: rfs-value(12px);
        &:before {
          content: '\2B07';
        }
        &:hover {
          background: $nikon_yellow-hover;
        }
      }
    }
  }
  .press_links {
    display: flex;
    text-transform: capitalize;
  }
  .press_link {
    display: flex;
    text-decoration: none;
    align-items: center;
    font-size: rfs-value(14px);
    &:before {
      content: '\2B05';
      transform: rotate(180deg);
      background: $nikon-dark-grey;
      color: $nikon-white;
      border-radius: 50%;
      font-size: rfs-value(12px);
      height: rfs-value(15px);
      width: rfs-value(15px);
      display: inline-flex;
      margin-right: 5px;
      align-items: center;
      justify-content: center;
    }
    &:hover {
      color: $nikon-black;
    }
  }
  .img-wrap {
    background: red;
  }
}
