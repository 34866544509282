@import 'styles/variables';
@import 'bootstrap/scss/mixins';

.ratings-reviews-tab-container {
  svg {
    display: inline-block !important;
  }

  @include media-breakpoint-up(lg) {
    max-width: 970px !important;
  }
}

.review-heading {
  display: inline-block;
  font-family: $headings-font-family;
  font-size: rfs-value(41px);
  font-weight: 600;
  line-height: 1.15;
  margin-bottom: 0;
  margin-top: 60px;
  padding-bottom: 60px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  width: 100%;

  @include media-breakpoint-up(md) {
    font-size: rfs-value(55px);
  }
}
