@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.lne-homepage {
  :global(.content-blocks) {
    margin-top: rfs-value(25px);
    margin-bottom: rfs-value(60px);

    @include media-breakpoint-up(md) {
      margin-top: rfs-value(50px);
      margin-bottom: rfs-value(100px);
    }
  }
}
