@import '../../../styles/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/vendor/rfs';

.brow {
  padding: 0;
  background: black;
  border-bottom: 1px solid rgba($nikon-yellow, 0.25);
  align-items: center;
  width: 100%;
  z-index: 6;

  @include media-breakpoint-up(md) {
    position: sticky;
    top: 0;
    z-index: 98;
  }

  .shopTab {
    display: none;
    color: #404040;
    padding: 15px;
    background-color: #fff;
    width: 100%;

    @include media-breakpoint-up(md) {
      display: flex;
      min-width: 25%;
      flex-direction: column;
      flex: 1;
      justify-content: center;
      color: #fff;
      background-color: transparent;
    }
  }

  .navbarBrand {
    font-family: $font-family-base !important;
    font-size: rfs-value(14px);

    @include media-breakpoint-up(md) {
      color: white;
      display: flex;
      font-size: rfs-value(16px);
    }

    img {
      width: 64px;
      margin-left: rfs-value(8px);
      border-right: 1px solid rgba(255, 255, 255, 0.5);
      padding-right: rfs-value(15px);
      margin-right: rfs-value(12px);
    }
  }

  :global(.nav-link),
  :global(.item-link-wrap),
  :global(.navbar-toggler) {
    font-weight: $font-weight-bold;
    font-size: rfs-value(12px);
    line-height: 1.7;
    letter-spacing: rfs-value(1.2px);
    text-transform: uppercase;
    padding: 13px 15px;
    color: white;
    text-decoration-color: transparent;
    transition: text-decoration 0.2s ease-in;

    &:hover,
    &:focus,
    &:global(.active) {
      color: white;
      text-decoration: underline;
      text-decoration-color: $nikon-yellow;
      text-decoration-thickness: rfs-value(2px);
      text-underline-offset: rfs-value(7px);
    }

    &:active,
    &:focus {
      box-shadow: none;
    }
  }

  :global(.item-link-wrap) {
    &:active,
    &:focus {
      outline: none;
    }
  }

  :global(.btn-yellow) {
    padding: rfs-value(9px 33px 8px) !important;
    margin: rfs-value(0 15px);
    line-height: 1.283;
  }

  .productTabList {
    background-color: #000;
    list-style-type: none;
    position: absolute;
    top: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    visibility: hidden;

    @include media-breakpoint-up(md) {
      min-width: 75%;
      position: relative;
      padding-left: 15px;
      margin: 0;
      flex: 2;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: auto;
      height: auto !important;
      overflow: unset;
      visibility: visible;
    }

    :global(.item) {
      @include media-breakpoint-up(md) {
        text-align: center;
      }

      &:first-child {
        :global(.nav-link) {
          margin-top: 15px;

          @include media-breakpoint-up(md) {
            margin-top: 0;
          }
        }
      }
    }

    :global(.item.subnav-tab) {
      :global(.collapse) {
        max-height: 0;
        overflow: hidden;
      }
      :global(.collapse.show) {
        max-height: 100vh;
        overflow: auto;
      }
    }

    :global(.subnav-button) {
      display: none;

      @include media-breakpoint-up(md) {
        display: table !important;
      }

      .brow-navdown-icon {
        display: inline-block;
        padding-left: 5px;
        transform: translateY(25%);

        @include media-breakpoint-up(md) {
          &::before {
            content: url(/icons/closeIconWhite.svg);
          }
        }
      }
    }

    :global(.subnav-button.collapsed) {
      .brow-navdown-icon {
        @include media-breakpoint-up(md) {
          &::before {
            content: url(/icons/downArrowWhite.svg);
          }
        }
      }
    }

    :global(.mobile-subnav-button) {
      display: block !important;

      @include media-breakpoint-up(md) {
        display: none !important;
      }
    }

    :global(.subnav-dropdown) {
      list-style-type: none;
      margin: 0;
      min-width: unset;
      padding: 0 0 0 15px;
      width: 100%;
      background-color: #000;

      @include media-breakpoint-up(md) {
        padding: 0;
        position: absolute;
        right: 0;
        top: 100%;
        white-space: nowrap;
        width: auto;
        border-radius: 0;
        z-index: 1;
      }

      ul {
        padding-left: 0;
      }
    }

    :global(.subnav-tab) {
      position: relative;

      @include media-breakpoint-up(md) {
        :global(.subnav-dropdown.collapsing) {
          overflow: hidden;
          transition-duration: 0.35s;
          transition-property: height, visibility;
          transition-timing-function: ease;
        }

        :global(.subnav-dropdown.collapse.show) {
          visibility: visible;
          opacity: 0;
          transition-delay: 0.3s !important;
        }

        &:hover,
        &:active {
          :global(.subnav-dropdown.collapse.show) {
            visibility: visible;
            opacity: 1;
            transition-delay: 0.3s !important;
          }
        }
      }
    }
  }

  .productTabList.collapse {
    @include media-breakpoint-down(md) {
      display: block !important;
      visibility: visible;
    }
  }

  // START OF MORE TAB STYLES
  .productTabList.has3OrLessMenuItems .menuTab,
  .productTabList.has4MenuItems .menuTab,
  .productTabList.hasMoreThan4Items .menuTab {
    @include media-breakpoint-down(md) {
      display: flex !important;
    }

    @include media-breakpoint-up(md);
    @include media-breakpoint-down(lg) {
      &:nth-child(n + 3) {
        display: none;
      }
    }

    @include media-breakpoint-up(lg);
    @include media-breakpoint-down(xl) {
      &:nth-child(n + 4) {
        display: none;
      }
    }
  }

  .productTabList.hasMoreThan4Items .menuTab {
    @include media-breakpoint-up(xl) {
      &:nth-child(n + 5) {
        display: none;
      }
    }
  }

  .productTabList.has4MenuItems .moreTab {
    @include media-breakpoint-up(xl) {
      display: none !important;
    }
  }

  .productTabList.has3OrLessMenuItems .moreTab {
    @include media-breakpoint-up(lg) {
      display: none !important;
    }
  }

  .moreTab {
    @include media-breakpoint-down(md) {
      display: none !important;
    }

    :global(.subnav-dropdown) > ul > li {
      @include media-breakpoint-up(lg) {
        &:first-child {
          display: none;
        }
      }

      @include media-breakpoint-up(xl) {
        &:nth-child(-n + 2) {
          display: none;
        }
      }
    }
  }

  // START OF MOBILE MENU STYLES
  .mobileMenuButton {
    background: 0 0;
    border: none;
    width: 100%;
    text-align: left;
    position: relative;
    border-radius: 0%;
  }

  .browMobileIcon {
    opacity: 1;
    position: absolute;
    margin-top: 1px;
    padding-top: 4px;
    right: 15px;
    transform: translateY(-50%);
    transition: opacity 350ms ease-out;
    will-change: opacity;
    vertical-align: middle;
    font-family: $icon-font;
    font-weight: $font-weight-normal;
    text-decoration: none;
    white-space: nowrap;
  }

  :global(.navbar-toggler) {
    .mobile-menu-navdown-icon {
      top: 50%;
      &::before {
        content: '␡';
      }
    }
  }

  :global(.navbar-toggler.collapsed) {
    .mobile-menu-navdown-icon {
      &::before {
        content: url(/icons/downArrowWhite.svg);
      }
    }
  }

  :global(.subnav-button-toggle.collapsed) {
    .brow-ddtoggler-icon {
      &::before {
        content: '+';
      }
    }
  }

  :global(.subnav-button-toggle) {
    .brow-ddtoggler-icon {
      margin-top: 8px;
      &::before {
        content: '-';
      }
    }
  }

  .shopLinkTab {
    @include media-breakpoint-down(md) {
      margin-bottom: 25px;
      padding: 13px 0;
    }
  }

  .shopLink {
    display: inline;
    min-width: unset;
    white-space: nowrap;

    &:focus {
      box-shadow: 0 0 4px 2px $nikon-box-shadow-color;
      outline: 0;
    }

    &:focus,
    &:active,
    &:hover {
      text-decoration: none;
    }

    @include media-breakpoint-down(md) {
      font-size: rfs-value(10px);
    }
  }

  .mobileShopTab {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 73px;
    padding: 15px;
    bottom: 0;
    background-color: #000;
    z-index: 6;

    .productInfo {
      padding: 15px;
      background-color: transparent;
      color: #f2f2f2;
    }

    :global(.navbarBrand) {
      color: #f2f2f2;
    }

    .shopLink {
      margin-left: auto;
    }
  }
}

// START OF STATIC CONTENT BROW's STYLES
.isStaticContentBrow {
  @include media-breakpoint-down(md) {
    .shopTab {
      display: flex !important;
    }

    .navbarBrand {
      padding: 0;
      margin: 0;
    }
  }
}
